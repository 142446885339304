import React from "react";
//import { Row , Container, Col} from "react-bootstrap";
import InfoItem from "./InfoItem";
import './appInfoDivCss.css';
import modePaiementImage from '../../data/appData/appImages/modePaiement/modePaiement.jpeg'
import qualityImage from '../../data/appData/appImages/quality/quality.png'
//import halalImage from '../../data/appData/appImages/halal/halal2.png'
import halalImage from '../../data/appData/appImages/photoResto/petitDej.png'
import star5 from '../../data/appData/appImages/star/star5S.jpg'
//import star4 from '../../data/appData/appImages/star/star4S.jpg'
//import star2 from '../../data/appData/appImages/star/star2S.jpg'

function Info(){

    let modePaiementDescription = "Carte bancaire à partir de 15€" ;
    let qualityDescription ="Notre restaurant vous proposent des repas vriés, préparés soigneusement pour répondre à votre gôut et à vos attentes. Consommées sur place, emportées ou livrées, vos commandes sont traitées par une equipe de professionnels compétents.";
    let halalDescription="Nous vous proposons une variété de petits déjeuné servis sur place ou à emporter tous les jours entre 7h à 14h   ";

    let evals = [
        [ star5, "Le 2024-04-10 18:52:00 Nadia", "Restaurant très classe. Les plats sont très délicieux. A recommander" ],
        [ star5, "Le 2024-01-22 22:22:25 Abdelali", "Espace très agréable pour se détendre et manger des bons plats." ],
        [ star5, "Le 2024-07-06 16:23:00 Anas", "Très bon. je recommande" ],
        [ star5, "Le 2023-06-17 17:20:35 Khadija", "C'est le meilleur resto de meknes. Un très bon espace pour se détendre et déguster des bons plats" ],
    ]
    return(
            <div>
                <InfoItem title="Evaluations" image={""}  description={""} isEvaluation={true} evals={evals}></InfoItem>
                <InfoItem title="Qualité" image={qualityImage}  description={qualityDescription} isEvaluation={false} evals={[[]]}></InfoItem>
                <InfoItem title="Petit déjouner" image={halalImage}  description={halalDescription} isEvaluation={false} evals={[[]]}></InfoItem>
                <InfoItem title="Modes de paiement" image={modePaiementImage}  description={modePaiementDescription} isEvaluation={false} evals={[[]]}></InfoItem>
                
            </div>
            


        
    );

}

export default Info